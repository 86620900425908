import { createStore } from "solid-js/store";

const [state, setState] = createStore({
  vitals: {
    data: {},
    get vitalsData() {
      return `${this.data}`;
    },
  },
});

export { state, setState };
